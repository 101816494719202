// import useFeatureHere from './useFeatureHere';
import { useImpersonationPageFeature } from 'featureFlag/useIsImpersonationEnabled';

/**
 * Gets the list of features for the cert app shell.
 */
export const useCertFeatureList = () => {
  const impersonationFeature = useImpersonationPageFeature();

  return [impersonationFeature];
};
